/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'node_modules/@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';
@import 'node_modules/@volosoft/ngx-lepton-x/assets/css/bootstrap-light.css';

html {
  overflow: hidden;
}

.hidenContent {
  background-color: #f0f4f7;
  opacity: 1 !important;
}

.flatfile_displayAsModal .flatfile-close-button {
  top: 30px !important;
}

#flatfile_iframe {
  padding: 40px 16px 16px !important;
}

:root {
  button:disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  .collapse.show {
    visibility: visible;
  }

  .btn {
    opacity: 0.9;
  }

  .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
    background-color: var(--bs-blue);
  }

  .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background-color: var(--bs-blue);
    border-color: var(--bs-blue);

    &::after {
      transform: rotate(45deg) scale(1) translate(-65%, -65%);
    }
  }

  .ant-tree-checkbox-checked::after {
    border: none;
  }

  .ant-tree-checkbox-inner {
    background-color: #f0f4f7;
    border-color: #e8eef3;
    border-width: 2px;
    min-width: 17.5px;
    min-height: 17.5px;
    border-radius: 0.25em;
  }

  .text-warning {
    color: var(--lpx-warning) !important;
  }

  .text-danger {
    color: var(--lpx-danger) !important;
  }

  .btn:hover {
    opacity: 1;
  }

  .bg-warning {
    --bs-bg-opacity: 1;
    background-color: var(--lpx-warning) !important;
  }

  .bg-danger {
    --bs-bg-opacity: 1;
    background-color: var(--lpx-danger) !important;
  }

  .bg-success {
    --bs-bg-opacity: 1;
    background-color: var(--bs-success) !important;
  }

  .btn-primary {
    --bs-btn-bg: var(--bs-link-color) !important;
  }

  .btn-yellow {
    --bs-btn-bg: var(--color-yellow) !important;
    color: white;

    &:hover {
      background-color: #c59615 !important;
    }
  }

  .btn-secondary {
    --bs-btn-bg: #6350e8 !important;
  }

  .btn-danger {
    --bs-btn-bg: var(--lpx-danger);
  }

  .nav-link.active {
    border-color: var(--bs-link-color) !important;
    color: var(--bs-link-color) !important;
  }

  .form-check-input:checked {
    border-color: var(--bs-blue) !important;
    background-color: var(--bs-blue);
  }

  .accordion-button:not(.collapsed) {
    --bs-accordion-active-color: var(--bs-link-color) !important;
  }

  .accordion-button:not(.collapsed) {
    &::after {
      filter: brightness(0) saturate(100%) invert(26%) sepia(62%) saturate(1006%) hue-rotate(189deg)
        brightness(95%) contrast(101%);
    }
  }

  .lpx-toolbar-container {
    z-index: 999;
  }

  --color-yellow: #f0b61c;
  --bs-success: #329932 !important;
  --lpx-warning: #f26822 !important;
  --lpx-danger: #e91d2a !important;

  --bs-warning: var(--lpx-warning) !important;
  --bs-danger: var(--lpx-danger) !important;
  --bs-link-color: #24489e !important;
  --lpx-primary: var(--bs-link-color) !important;
  --lpx-secondary: #6350e8 !important;

  --selected: #c9cbd1;

  .lpx-theme-dark {
    --lpx-logo: url('/assets/images/logo/SitePlant.png');
    --lpx-logo-icon: url('/assets/images/logo/SitePlant.png');
    --lpx-brand: #edae53;
    background-color: var(--lpx-navbar-color);
  }

  .lpx-theme-dim {
    --lpx-logo: url('/assets/images/logo/SitePlant.png');
    --lpx-logo-icon: url('/assets/images/logo/SitePlant.png');
    --lpx-brand: #f15835;
  }

  .lpx-theme-light {
    --lpx-logo: url('/assets/images/logo/SitePlant.png');
    --lpx-logo-icon: url('/assets/images/logo/SitePlant.png');
    --lpx-brand: var(--bs-link-color);
  }
}

body {
  padding-top: 32px;
}

.cdk-overlay-container {
  z-index: 10000;
}

ngb-typeahead-window.dropdown-menu.show {
  z-index: 10500;
}

abp-button:has(> button:disabled) {
  pointer-events: none;
}

.bg-secondary {
  background-color: var(--lpx-secondary) !important;
}

.card {
  background-color: var(--lpx-navbar-color);
}

.table-column-text {
  display: flex;
  width: 100%;
  text-wrap: wrap;
  word-break: break-all;
}

.card-body {
  background-color: var(--lpx-navbar-color);
}

.custom-tab-content {
  .card {
    background-color: var(--lpx-navbar-color) !important;
  }
}

.full {
  background-color: var(--lpx-navbar-color);
}

.modal-footer {
  display: flex;
  gap: 20px;
  position: relative;
}

.cursor-pointer {
  cursor: pointer;
}

.modal-content {
  background-color: var(--lpx-navbar-color);
}

.ps {
  overflow: auto !important;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* IE */
  overflow: -moz-scrollbars-none; /* Firefox */
}

.selected {
  border-radius: 6px;
  background-color: var(--selected);
}

@media screen and (max-width: 770px) {
  body {
    padding-top: 0;
  }

  .lpx-topbar-container {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .modal-footer {
    justify-content: center;
  }

  .select option {
    margin: 0 !important;
    padding: 0 !important;
  }

  .lpx-content {
    padding: 1.5em 1em 3.5em 1em !important;
  }

  .lpx-content-container {
    border-radius: 0;
    overflow-x: hidden;
  }

  .lpx-footbar-container {
    display: none !important;
  }

  .lpx-mobile-nav-tab-left {
    width: 40% !important;
  }

  .lpx-mobile-nav-tab-center {
    padding: 0 !important;
    margin: 0 !important;
    width: 20% !important;
  }

  .lpx-mobile-nav-tab-right {
    width: 40% !important;
  }

  .lpx-mobile-nav-tab {
    border-radius: 0;
  }

  .lpx-topbar {
    border-radius: 0 !important;
  }
}
